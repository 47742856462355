import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Info = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 22.09 22.09'
  >
    <g>
      <path
        d='M11,18a1,1,0,0,1-1-1V11.22a1,1,0,1,1,2,0V17A1,1,0,0,1,11,18Z'
        fill={ color }
      />
      <circle
        cx='11.04'
        cy='6.73'
        fill={ color }
        r='1.2'
      />
      <path
        d='M11,22.09A11,11,0,1,1,22.09,11,11.06,11.06,0,0,1,11,22.09ZM11,2a9,9,0,1,0,9.05,9A9.06,9.06,0,0,0,11,2Z'
        fill={ color }
      />
    </g>
  </svg>
);

Info.propTypes = { ...iconPropTypes };

Info.defaultProps = { ...iconDefaultProps };

export default Info;