import PropTypes from 'prop-types';
import { classes } from '@/helpers/styling';

const LayoutWrapperVertical = ({
  children,
  disable,
  className,
  disableTopOnMobile,
  disableBottomOnMobile,
  disableTopOnDesktop,
  disableBottomOnDesktop,
  extraPagePadding
}) => {
  return (
    <div
      className={ classes(
        'layout-wrapper-vertical',
        {
          '--is-disabled': disable,
          '--extra-page-padding': extraPagePadding,
          '--is-disabled-top-desktop': disableTopOnDesktop,
          '--is-disabled-bottom-desktop': disableBottomOnDesktop,
          '--is-disabled-top-mobile': disableTopOnMobile,
          '--is-disabled-bottom-mobile': disableBottomOnMobile
        },
        className
      ) }
    >
      { children }
    </div>
  );
};

LayoutWrapperVertical.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  disableTopOnMobile: PropTypes.bool,
  disableBottomOnMobile: PropTypes.bool,
  disableTopOnDesktop: PropTypes.bool,
  disableBottomOnDesktop: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
  extraPagePadding: PropTypes.bool
};

LayoutWrapperVertical.defaultProps = {
  disableTopOnMobile: false,
  disableBottomOnMobile: false,
  disableTopOnDesktop: false,
  disableBottomOnDesktop: false,
  disable: false,
  className: '',
  extraPagePadding: false
};

export default LayoutWrapperVertical;