import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import GenresWithSlashSeparators from '@/components/component-library/GenresWithSlashSeparators';
import PlayRoundedIcon from '@/components/component-library/Icon/types/PlayRounded';
import PauseRoundedIcon from '@/components/component-library/Icon/types/PauseRounded';
import Text, { FONT_SIZE_040 } from '@/components/component-library/Text';
import { classes } from '@/helpers/styling';
import useShowEdgePlayback from '@/hooks/audio/useShowEdgePlayback';

const SlateOverlayContent = ({
  streamingContext,
  showId
}) => {
  const {
    handleClickPlay,
    paused,
    episode,
    isCurrentlyPlayingShow
  } = useShowEdgePlayback({
    showId,
    streamingContext,
    disableFetchEpisodes: true
  });

  const displayContent = !!handleClickPlay && !!episode?.id;

  let playPauseButtonIcon = <PlayRoundedIcon />;
  
  let upNextText = useMemo(() => (
    episode ? `Play Season ${episode.season} Episode ${episode.episode_number}` : ''
  ), [episode]);

  if (isCurrentlyPlayingShow && !paused) {
    upNextText = 'Pause';
    playPauseButtonIcon = <PauseRoundedIcon />;
  }

  const handleClickPlayResume = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    handleClickPlay();
  }, [handleClickPlay]);

  return (
    <div
      className={ classes(
        'slate-overlay__info-section',
        { '--is-visible': displayContent }
      ) }
      onClick={ displayContent ? handleClickPlayResume : undefined }
    >
      <div className='slate-overlay__play'>
        { playPauseButtonIcon }
      </div>
      <div className='slate-overlay__details'>
        <Text
          bold
          className='slate-overlay__title'
          overflowHidden
          size={ FONT_SIZE_040 }
        >
          { episode ? upNextText : '' }
        </Text>
        <Text
          className='slate-overlay__genres'
          overflowHidden
          size={ FONT_SIZE_040 }
        >
          <GenresWithSlashSeparators genres={ episode ? episode.genres : [] } useStringFormat />
        </Text>
      </div>
    </div>
  );
};

SlateOverlayContent.propTypes = {
  showId: PropTypes.string,
  streamingContext: PropTypes.string
};

SlateOverlayContent.defaultProps = {
  showId: null,
  streamingContext: undefined
};

export default SlateOverlayContent;
