import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import useEdgeByShowId from '@/react-query/edges/hooks/useEdgeByShowId';
import { getFirstEpisodeForShow } from '@/helpers/pages/channel';
import useEpisodesByShow from '@/react-query/episodes/hooks/useEpisodesByShow';
import {
  selectAudioPaused,
  selectCurrentEpisodeNumber,
  selectCurrentEpisodeSeason,
  selectCurrentShowId,
  selectKeakiePlaybackMode,
  selectCurrentEpisode
} from '@/state/selectors/audio';
import useAudioReduxControls from '@/hooks/audio/useAudioReduxControls';
import useAudioReduxControlsCustom from '@/hooks/audio/useAudioReduxControlsCustom';

const selector = (state) => ({
  keakiePlaybackMode: selectKeakiePlaybackMode(state),
  currentShowId: selectCurrentShowId(state),
  currentSeasonNumber: selectCurrentEpisodeSeason(state),
  currentEpisodeNumber: selectCurrentEpisodeNumber(state),
  currentEpisode: selectCurrentEpisode(state),
  paused: selectAudioPaused(state)
});

const useShowEdgePlayback = ({
  showId,
  disableFetchEpisodes = false,
  streamingContext
} = {}) => {
  const {
    keakiePlaybackMode,
    currentShowId,
    currentSeasonNumber,
    currentEpisodeNumber,
    currentEpisode,
    paused
  } = useSelector(selector, shallowEqual);
  const { handlePlay, handlePause } = useAudioReduxControls();
  const { handlePlayEpisodeFromShow } = useAudioReduxControlsCustom({ streamingContext });
  const { episodes: episodePreviews, isFetching: isLoadingEpisodes } = useEpisodesByShow({
    id: showId,
    enabled: !disableFetchEpisodes
  });
  const { edge: showUserEdge = {}, isLoading: isLoadingShowEdge } = useEdgeByShowId({ id: showId });
  const firstEpisodeForShow = useMemo(() => getFirstEpisodeForShow({ episodes: episodePreviews }), [episodePreviews]);

  const { next_episode: edgeEpisode, next_episode_position: edgeEpisodePosition } = showUserEdge;

  let nextEpisode;

  if (edgeEpisode?.is_approved) {
    // Only use approved episodes for the show hero play button
    nextEpisode = edgeEpisode;
  } else if (firstEpisodeForShow) {
    nextEpisode = firstEpisodeForShow;
  }

  const isCurrentlyPlayingShow = (showId === currentShowId);
  const nextEpisodePosition = edgeEpisode?.is_approved
    ? edgeEpisodePosition
    : 0;

  // Handle when the user clicks to play the channel. Play the first episode
  const handleClickPlayButton = useCallback(() => handlePlayEpisodeFromShow({
    episode: nextEpisode,
    seekPosition: nextEpisodePosition
  }), [handlePlayEpisodeFromShow, nextEpisode, nextEpisodePosition]);

  let handleClickPlay;
  let seasonNumberToPlay;
  let episodeNumberToPlay;

  if (currentShowId && isCurrentlyPlayingShow && (keakiePlaybackMode === 'show')) {
    // The currently playing track belongs to this show
    nextEpisode = currentEpisode;
    seasonNumberToPlay = currentSeasonNumber;
    episodeNumberToPlay = currentEpisodeNumber;

    if (paused) {
      handleClickPlay = handlePlay;
    } else {
      handleClickPlay = handlePause;
    }
  } else if (nextEpisode && nextEpisode.id) {
    // We have an episode to line up which we retrieved from either the episodes list or the episode edge
    const { season, episode_number } = nextEpisode;

    seasonNumberToPlay = season;
    episodeNumberToPlay = episode_number;
    handleClickPlay = handleClickPlayButton;
  }

  const playButtonIsLoading = !handleClickPlay && (
    isLoadingEpisodes
      || isLoadingShowEdge
  );

  return {
    handleClickPlay,
    seasonNumberToPlay,
    episodeNumberToPlay,
    isLoading: playButtonIsLoading,
    paused,
    episode: nextEpisode || {},
    isCurrentlyPlayingShow
  };
};

export default useShowEdgePlayback;