import {
  memo, useCallback, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import SlateOverlayContent from './SlateOverlayContent';
import ShowPreviewSlate from '@/components/component-library/ShowPreviewSlate';
import useIntersectionObserver from '@/hooks/browser/useIntersectionObserver';
import useMainScrollElement from '@/hooks/layout/useMainScrollElement';

const ConnectedShowPreviewSlate = ({
  assets,
  loading,
  showId,
  showSlug,
  hasBorderRadius,
  useOverlay,
  useOverlayContent,
  disableHoverScale,
  displayShowInfo,
  showTitle,
  showFormat,
  streamingContext,
  intersectionRoot,
  onClick,
  priority,
  altText,
  centerShowInfo
}) => {
  const [hoverStateActive, setHoverStateActive] = useState(false);
  const [hasEnteredViewOnce, setHasEnteredViewOnce] = useState(false);
  const hoverTimeoutRef = useRef();
  const { ref: mainScrollElementRef } = useMainScrollElement({ notifyRefAttached: true });

  const handleHoverStateChange = useCallback((newHoverState) => {
    if (newHoverState) {
      clearTimeout(hoverTimeoutRef.current);
      setHoverStateActive(true);
    } else {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = setTimeout(() => setHoverStateActive(false), 300);
    }
  }, []);

  const handleEnterView = useCallback(() => {
    setHasEnteredViewOnce(true);
  }, []);

  // Track whether the slate has entered the viewport
  // We force an isLoading state if the slate cannot be seen
  const {
    ref,
    inView
  } = useIntersectionObserver({
    rootMargin: hasEnteredViewOnce ? '0% 33% 0% 33%' : '0% 25% 0% 25%',
    root: intersectionRoot || mainScrollElementRef.current,
    // triggerInViewOnceOnly: false,
    onEnterView: handleEnterView
  });

  const isLoading = loading || !inView || !showId;

  const handleClickSlate = useCallback(
    () => onClick({
      id: showId,
      slug: showSlug
    }),
    [showSlug, showId, onClick]
  );

  return (
    <ShowPreviewSlate
      altText={ altText }
      assets={ assets }
      centerShowInfo={ centerShowInfo }
      disableHoverScale={ disableHoverScale }
      displayShowInfo={ displayShowInfo }
      hasBorderRadius={ hasBorderRadius }
      loading={ isLoading }
      onClick={ (!isLoading && onClick) ? handleClickSlate : undefined }
      onHoverStateChange={ handleHoverStateChange }
      overlayChildren={ (!isLoading && useOverlay && useOverlayContent && !!showId && hoverStateActive)
        ? (
          <SlateOverlayContent
            enableFetchEdge={ hoverStateActive }
            showId={ showId }
            streamingContext={ streamingContext }
          />
        )
        : null }
      priority={ priority }
      ref={ ref }
      showFormat={ showFormat }
      showId={ showId }
      showSlug={ showSlug }
      showTitle={ showTitle }
      useOverlay={ useOverlay }
    />
  );
};

ConnectedShowPreviewSlate.propTypes = {
  showId: PropTypes.string,
  showSlug: PropTypes.string,
  assets: PropTypes.object,
  loading: PropTypes.bool,
  hasBorderRadius: PropTypes.bool,
  useOverlay: PropTypes.bool,
  useOverlayContent: PropTypes.bool,
  displayShowInfo: PropTypes.bool,
  disableHoverScale: PropTypes.bool,
  showTitle: PropTypes.string,
  showFormat: PropTypes.string,
  streamingContext: PropTypes.string,
  intersectionRoot: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  onClick: PropTypes.func,
  priority: PropTypes.bool,
  altText: PropTypes.string,
  centerShowInfo: PropTypes.bool
};

ConnectedShowPreviewSlate.defaultProps = {
  showId: null,
  showSlug: null,
  assets: null,
  loading: false,
  hasBorderRadius: true,
  useOverlay: false,
  useOverlayContent: false,
  displayShowInfo: true,
  disableHoverScale: false,
  showTitle: '',
  showFormat: '',
  streamingContext: undefined,
  intersectionRoot: null,
  onClick: undefined,
  priority: false,
  altText: '',
  centerShowInfo: false
};

export default memo(ConnectedShowPreviewSlate);