import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Text from '@/components/component-library/Text';
import { FONT_SIZE_050, FONT_SIZE_100 } from '@/config/fontSizes';
import { classes } from '@/helpers/styling';
import Link from '@/components/component-library/Link';

const Badge = React.forwardRef(({
  title,
  onClick,
  href,
  loading,
  className,
  largeSize,
  isClickable,
  disableTextWrap,
  fontSize
}, ref) => {
  return (
    <Link
      className={ classes(styles.badge, className, {
        [styles.isClickable]: !!(onClick || href) || isClickable,
        [styles.isLoading]: loading,
        [styles.largeSize]: largeSize
      }) }
      href={ href }
      onClick={ onClick }
    >
      <Text
        bold
        className={ classes(styles.badgeText, { [styles.disableTextWrap]: disableTextWrap }) }
        ref={ ref }
        size={ fontSize || (largeSize ? FONT_SIZE_100 : FONT_SIZE_050) }
        useHeaderFont
        useSpan
      >
        { title }
      </Text>
    </Link>
  );
});

Badge.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  largeSize: PropTypes.bool,
  isClickable: PropTypes.bool,
  disableTextWrap: PropTypes.bool,
  fontSize: PropTypes.string
};

Badge.defaultProps = {
  title: '',
  onClick: undefined,
  href: '',
  loading: false,
  className: '',
  largeSize: false,
  isClickable: false,
  disableTextWrap: false,
  fontSize: undefined
};

export default memo(Badge);