import { useCallback, useMemo } from 'react';
import useQuery from '@/react-query/common/hooks/useQuery';
import getChannelUserEdge from '@/helpers/api/channels/getChannelUserEdge';
import { QK_EDGE_BY_SHOW_ID } from '@/config/query-keys';
import { formatChannel } from '@/helpers/common';

export const createEdgeByShowIdQueryKey = ({ id } = {}) => [QK_EDGE_BY_SHOW_ID, { id }];

const useEdgeByShowId = ({ enabled = true, id } = {}) => {
  const fetchEdge = useCallback(() => getChannelUserEdge({ channelId: id }), [id]);
  
  const results = useQuery({
    queryKey: createEdgeByShowIdQueryKey({ id }),
    queryFn: fetchEdge,
    enabled: enabled && !!id,
    retry: 1,
    staleTime: 1000 * 60 * 5 // 5 minutes
  });

  const {
    error,
    data
  } = results;

  const {
    json: edge,
    notFound,
    status: statusCode
  } = data || {};

  const edgeFormatted = useMemo(() => ({
    ...edge,
    next_episode: formatChannel((edge || {}).next_episode)
  }), [edge]);

  return {
    ...results,
    error,
    notFound,
    statusCode,
    edge: edgeFormatted
  };
};

export default useEdgeByShowId;