import PropTypes from 'prop-types';
import Link from '.';
import pageKeys from '@/helpers/routing/constants';

const ShowLink = ({
  show,
  children,
  ...props
}) => <Link { ...props } href={ pageKeys.shows(show) }>{ children }</Link>;

ShowLink.propTypes = {
  show: PropTypes.string,
  children: PropTypes.any.isRequired
};

ShowLink.defaultProps = { show: '' };

export default ShowLink;