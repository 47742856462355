import {
  iconPropTypes,
  iconDefaultProps,
  iconCommonProps
} from '../propTypes';

const PlayRounded = ({ className, color }) => (
  <svg
    { ...iconCommonProps }
    className={ className }
    fill='none'
    viewBox='0 0 31 30'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M29.368 12.578 5.01.385C3.03-.605 0 .355 0 2.805v24.38c0 2.197 2.816 3.52 5.01 2.42l24.358-12.188c2.173-1.084 2.18-3.755 0-4.84Z'
      fill={ color }
    />
  </svg>
);

PlayRounded.propTypes = { ...iconPropTypes };

PlayRounded.defaultProps = { ...iconDefaultProps };

export default PlayRounded;