import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const { api: { HOST, GET_CHANNEL_USER_EDGE } } = CONFIG;

// Get the edge between user and a channel
// The edge contains the user's current playback position in that channel
const getChannelUserEdge = ({ channelId }) => {
  return callApi({
    url: HOST(GET_CHANNEL_USER_EDGE) + GET_CHANNEL_USER_EDGE
      .replace(':channel_id', channelId)
  });
};

export default getChannelUserEdge;