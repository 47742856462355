import PropTypes from 'prop-types';
import React, { memo } from 'react';
// import startCase from 'lodash.startcase';
import ShowInfo from './ShowInfo';
import SlateOverlay from './SlateOverlay';
import ShowLink from '@/components/component-library/Link/ShowLink';
import SlateImage from '@/components/component-library/Image/KeakieImageSlate';
import { SMALL } from '@/config/assets';
import { classes } from '@/helpers/styling';
import { isTouchDevice } from '@/helpers/browser';

const ShowPreviewSlate = React.forwardRef(({
  assets,
  loading,
  showId,
  className,
  onHoverStateChange,
  hasBorderRadius,
  useOverlay,
  disableHoverScale,
  displayShowInfo,
  showTitle,
  centerShowInfo,
  overlayChildren,
  showSlug,
  onClick,
  priority,
  altText
}, ref) => {
  const isLoading = loading || !showId;

  const handleClickSlate = (!isLoading && onClick) ? onClick : undefined;

  const imageComponent = (
    <SlateImage
      alt={ altText }
      assetSize={ SMALL }
      assetsObj={ assets }
      className='show-preview-slate'
      imageClassName='show-preview-slate__image'
      loading={ isLoading }
      priority={ priority }
      ref={ ref }
    />
  );

  return (
    <ShowLink className={ classes('show-preview-slate__wrapper', { '--has-border-radius': hasBorderRadius }) } onClick={ handleClickSlate } show={ showSlug }>
      {
        isLoading
          ? imageComponent
          : (
            <div
              className={ classes(
                'show-preview-slate__component',
                className,
                {
                  '--with-hover-scale': !disableHoverScale && !!showSlug,
                  '--is-clickable': !!showSlug
                }
              ) }
            >
              <div
                className={ classes(
                  'show-preview-slate__scalable-contents',
                  {
                    '--is-loading': isLoading,
                    '--has-border-radius': hasBorderRadius
                  }
                ) }
              >
                { imageComponent }
              </div>
              { !isLoading && useOverlay && !isTouchDevice() && (
                <SlateOverlay onHoverStateChange={ onHoverStateChange }>
                  { overlayChildren }
                </SlateOverlay>
              ) }
            </div>
          )
      }
      { displayShowInfo && (
        <ShowInfo
          centered={ centerShowInfo }
          loading={ isLoading }
          showTitle={ showTitle }
        />
      ) }
    </ShowLink>
  );
});

ShowPreviewSlate.propTypes = {
  showId: PropTypes.string,
  assets: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string,
  onHoverStateChange: PropTypes.func,
  hasBorderRadius: PropTypes.bool,
  useOverlay: PropTypes.bool,
  displayShowInfo: PropTypes.bool,
  disableHoverScale: PropTypes.bool,
  showTitle: PropTypes.string,
  centerShowInfo: PropTypes.bool,
  showSlug: PropTypes.string,
  overlayChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  priority: PropTypes.bool,
  altText: PropTypes.string
};

ShowPreviewSlate.defaultProps = {
  showId: null,
  assets: null,
  loading: false,
  className: '',
  onHoverStateChange: undefined,
  hasBorderRadius: true,
  useOverlay: false,
  displayShowInfo: true,
  disableHoverScale: false,
  showTitle: '',
  centerShowInfo: false,
  showSlug: '',
  overlayChildren: null,
  onClick: undefined,
  priority: false,
  altText: ''
};

export default memo(ShowPreviewSlate);