import PropTypes from 'prop-types';
import { classes } from '@/helpers/styling';
import Text from '@/components/component-library/Text';

// Spacer element to force the show title onto two lines
const ShowTitleSpacerElement = () => {
  return (
    <>
      { [...Array(2)].map((v, i) => (
        <Text
          bold
          className='show-preview-slate__title-placeholder'
          key={ i }
        >
          Placeholder
        </Text>
      )) }
    </>
  );
};

const ShowInfo = ({
  loading,
  showTitle,
  showFormat,
  centered
}) => {
  return (
    <div className='show-preview-slate__info'>
      <div className={ classes('show-preview-slate__title-wrapper', { '--is-centered': centered }) }>
        { !loading && <ShowTitleSpacerElement /> }
        { loading ? (
          <>
            <Text
              className='show-preview-slate__title --is-loading'
              loading
            />
            { showFormat && (
              <Text
                className='show-preview-slate__title --is-loading'
                loading
              />
            ) }
          </>
        ) : (
          <Text
            bold
            className={ classes('show-preview-slate__title', { '--is-centered': centered }) } useHeaderFont
          >
            { showTitle }
          </Text>
        ) }
      </div>
      { showFormat && (
        <Text
          className={ classes('show-preview-slate__format', { '--is-loading': loading }) }
          loading={ loading }
        >
          { showFormat }
        </Text>
      ) }
    </div>
  );
};

ShowInfo.propTypes = {
  loading: PropTypes.bool,
  showTitle: PropTypes.string,
  showFormat: PropTypes.string,
  centered: PropTypes.bool
};

ShowInfo.defaultProps = {
  loading: false,
  showTitle: '',
  showFormat: '',
  centered: false
};

export default ShowInfo;