import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const PauseRounded = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 25 36'
  >
    <rect
      fill={ color } height={ 35.727 } rx={ 3 }
      width={ 8.932 }
    />
    <rect
      fill={ color } height={ 35.727 } rx={ 3 }
      width={ 8.932 } x={ 15.631 }
    />
  </svg>
);

PauseRounded.propTypes = { ...iconPropTypes };

PauseRounded.defaultProps = { ...iconDefaultProps };

export default PauseRounded;