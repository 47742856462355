import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { size } from '@/helpers/lodash';
import { classes } from '@/helpers/styling';
import pageKeys from '@/helpers/routing/constants';
import Span from '@/components/component-library/Span';
import Link from '@/components/component-library/Link';

const GenresWithSlashSeparators = ({
  genres,
  onClickGenre,
  useStringFormat,
  enableLinks
}) => {
  if (!size(genres)) { return null; }

  return !useStringFormat
    ? genres.map((g, i) => {
      const genresSize = size(genres);
      const isLast = (i === (genresSize - 1));
      const showSlash = (genresSize && !isLast);

      return (
        <Fragment key={ `${ g.id }-genre-title` }>
          <Link
            className={ classes('genres-with-slash__genre', {
              '--is-clickable': !!(onClickGenre || enableLinks),
              '--with-right-margin': showSlash,
              '--with-left-margin': i > 0
            }) }
            href={ enableLinks ? pageKeys.genre(g.slug) : undefined }
            onClick={ onClickGenre ? () => onClickGenre(g) : undefined }
          >
            { `${ g.title }` }
          </Link>
          { showSlash && <Span>/</Span> }
        </Fragment>
      );
    }) : (
      <Span className='genres-with-slash__static'>
        {
          genres.reduce((combinedGenres, g, i) => {
            const genresSize = size(genres);
            const isLast = (i === (genresSize - 1));
            const showSlash = (genresSize && !isLast);

            return `${ combinedGenres }${ g.title }${ showSlash ? ' / ' : '' }`;
          }, '')
        }
      </Span>
    );
};

GenresWithSlashSeparators.propTypes = {
  genres: PropTypes.arrayOf(PropTypes.object),
  onClickGenre: PropTypes.func,
  useStringFormat: PropTypes.bool,
  enableLinks: PropTypes.bool
};

GenresWithSlashSeparators.defaultProps = {
  genres: [],
  onClickGenre: undefined,
  useStringFormat: false,
  enableLinks: false
};

export default memo(GenresWithSlashSeparators);