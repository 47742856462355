import { useEffect, useState } from 'react';
import CONFIG from '@/config/global';
import { getBrowserSize, shouldRenderForDesktop } from '@/helpers/layout';
import useInitialRender from '@/hooks/browser/useInitialRender';
import useWindowSize from '@/hooks/browser/useWindowSize';

const useShouldRenderForDesktop = () => {
  const { isFirstRender } = useInitialRender();

  const [shouldRender, setShouldRender] = useState(shouldRenderForDesktop({ isFirstRender }));
  
  const { width } = useWindowSize({ debounceTime: CONFIG.layoutShiftDebounceMs });
  const browserSize = getBrowserSize(width);

  useEffect(() => {
    setShouldRender(shouldRenderForDesktop());
  }, [browserSize]);

  return shouldRender;
};

export default useShouldRenderForDesktop;