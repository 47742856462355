import {
  memo, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@/components/component-library/Icon/types/Info';
import { classes } from '@/helpers/styling';
import { isTouchDevice } from '@/helpers/browser';

const SlateOverlay = ({
  children,
  onHoverStateChange
}) => {
  const [hoverStateActive, setHoverStateActive] = useState(false);

  useEffect(() => {
    if (onHoverStateChange) {
      onHoverStateChange(hoverStateActive);
    }
  }, [hoverStateActive, onHoverStateChange]);

  const handleSlateMouseEnter = () => {
    if (!isTouchDevice()) {
      setHoverStateActive(true);
    }
  };

  const handleSlateMouseLeave = () => {
    if (!isTouchDevice()) {
      setHoverStateActive(false);
    }
  };
  return (
    <>
      <div className={ classes('slate-overlay', { '--is-active': hoverStateActive }) } />
      <div
        className={ classes('slate-overlay__content --justify-end', { '--is-active': hoverStateActive }) }
        onMouseEnter={ handleSlateMouseEnter }
        onMouseLeave={ handleSlateMouseLeave }
      >
        <div className='slate-overlay__info-icon-wrapper'><InfoIcon /></div>
        { children }
      </div>
    </>
  );
};

SlateOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onHoverStateChange: PropTypes.func
};

SlateOverlay.defaultProps = {
  children: null,
  onHoverStateChange: undefined
};

export default memo(SlateOverlay);